<template>
  <div>
    <b-row class="mb-2">
      <b-col>
        <h1>{{ $t("purchases.createPayment") }}</h1>
        <h5 class="text-primary">
          {{ $t("purchases.configuration") }}
        </h5>
      </b-col>
      <b-col class="d-flex justify-content-end align-items-center mr-2">
        <b-row style="align-items: center">
          <b-col>
            <router-link to="/platform/purchases">
              <b-button variant="danger">
                {{
                  $t("dataGeneric.goBack")
                }}
              </b-button>
            </router-link>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-overlay
      variant="transparent"
      :show="show"
    >
      <form-wizard
        color="#7367F0"
        :title="null"
        :subtitle="null"
        shape="square"
        :finish-button-text="$t('dataGeneric.create')"
        :back-button-text="$t('dataGeneric.goBack')"
        :next-button-text="$t('next')"
        class="mb-3"
        @on-complete="formSubmitted"
      >
        <!-- Gestión de pago tab -->
        <tab-content :title="$t('purchases.wizard')">
          <b-row>
            <b-col
              cols="12"
              class="mb-2"
            >
              <h5 class="mb-0">
                {{ $t("purchases.wizard") }}
              </h5>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              class="mb-2 "
            >
              <b-form-group :label="$t('purchases.label')">
                <b-form-radio-group
                  id="btn-radios-1"
                  v-model="isManual"
                  :options="options"
                  button-variant="outline-primary"
                  size="lg"
                  name="radios-btn-default"
                  buttons
                />
              </b-form-group>
            </b-col>
          </b-row>
        </tab-content>
        <!-- Información general tab -->
        <tab-content
          v-if="isManual == true"
          :title="$t('purchases.label2')"
          :before-change="validationForm"
        >
          <validation-observer
            ref="info"
            tag="form"
          >
            <b-row>
              <b-col
                cols="12"
                class="mb-2"
              >
                <h5 class="mb-0">
                  {{ $t("purchases.label3") }}
                </h5>
              </b-col>
              <b-col>
                <validation-provider
                  v-slot="validationContext"
                  name="name"
                  rules="required|min:3"
                >
                  <b-form-group :label="$t('dataGeneric.name') + ' *'">
                    <b-form-input
                      id="name"
                      v-model="name"
                      autocomplete="new-password"
                      :state="validationContext.errors.length > 0 ? false : null"
                      :placeholder="$t('purchases.label4')"
                    />
                    <small class="text-danger">{{ fix(validationContext) }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group
                  class="mt-1"
                  label=""
                >
                  <b-form-radio-group
                    id="btn-radios-1"
                    v-model="elemento"
                    :options="optionsElemento"
                    button-variant="outline-primary"
                    size="lg"
                    name="radios-btn-default"
                    buttons
                  />
                </b-form-group>
              </b-col>
              <b-col
                v-if="elemento"
                cols="9"
                class="d-flex align-items-center"
              >
                <b-col cols="9">
                  <validation-provider
                    v-slot="{ errors }"
                    name="content"
                    rules="required"
                  >
                    <b-form-group
                      :label="$t('modal.content') + ' *'"
                      :invalid-feedback="$t('purchases.contentFormValid')"
                      :state="errors.length > 0 ? false : null"
                    >
                      <b-col
                        cols="13"
                        @click="selectElement(0)"
                      >
                        <b-form-input
                          id="content"
                          v-model="contentName"
                          autocomplete="new-password"
                          :state="errors.length > 0 ? false : null"
                          :placeholder="$t('modal.content')"
                          required
                          disabled
                        />
                      </b-col>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col cols="12">
                  <b-button
                    variant="info"
                    @click="selectElement(0)"
                  >
                    {{ $t('select') }}
                  </b-button>
                </b-col>
              </b-col>
              <b-col
                v-else
                md="9"
                class="d-flex align-items-center"
              >
                <b-col md="9">
                  <validation-provider
                    v-slot="{ errors }"
                    name="category"
                    rules="required"
                  >
                    <b-form-group
                      :label="$t('modal.category') + ' *'"
                      :invalid-feedback="$t('purchases.categoryFormValid')"
                      :state="errors.length > 0 ? false : null"
                    >
                      <b-col
                        cols="13"
                        @click="selectElement(1)"
                      >
                        <b-form-input
                          id="category"
                          v-model="categoryName"
                          autocomplete="new-password"
                          :state="errors.length > 0 ? false : null"
                          :placeholder="$t('modal.category')"
                          disabled
                        />
                      </b-col>
                      <!--small class="text-danger">{{ errors[0] }}</small-->
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col>
                  <b-button
                    variant="info"
                    @click="selectElement(1)"
                  >
                    {{ $t('purchases.selectCategory') }}
                  </b-button>
                </b-col>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                v-if="subgroups"
                md="4"
              >
                <b-form-group
                  v-if="hasSubgroups && checkPermissions('users.add_subgroup')"
                  :label="$t('Subgroup')"
                >
                  <v-select
                    id="subgroup"
                    v-model="subgroup"
                    :options="subgroups"
                    label="name"
                    :placeholder="$t('SinGrupoUsuario')"
                    aria-autocomplete="new-password"
                    autocomplete="new-password"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </validation-observer>
        </tab-content>

        <tab-content
          v-else
          :title="$t('purchases.label5')"
          :before-change="validationForm"
        >
          <validation-observer
            ref="info"
            tag="form"
          >
            <b-row>
              <b-col
                cols="12"
                class="mb-2"
              >
                <h5 class="mb-0">
                  {{ $t("purchases.label3") }}
                </h5>
              </b-col>
              <b-col md="12">
                <validation-provider
                  v-slot="validationContext"
                  name="name"
                  rules="required|min:3"
                >
                  <b-form-group :label="$t('dataGeneric.name') + ' *'">
                    <b-form-input
                      id="name"
                      v-model="name"
                      autocomplete="new-password"
                      :state="validationContext.errors.length > 0 ? false : null"
                      :placeholder="$t('purchases.label4')"
                      minlength="3"
                    />
                    <small class="text-danger">{{ fix(validationContext) }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="6">
                <validation-provider
                  v-slot="{ errors }"
                  name="code"
                  rules="required"
                >
                  <b-form-group
                    :label="$t('purchases.code') + ' *'"
                    :invalid-feedback="$t('purchases.codeFormValid')"
                    :state="errors.length > 0 ? false : null"
                  >
                    <b-form-input
                      id="code"
                      v-model="code"
                      autocomplete="new-password"
                      label="text"
                      :state="errors.length > 0 ? false : null"
                    />
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="2">
                <validation-provider
                  v-slot="{ errors }"
                  name="price"
                  rules="required"
                >
                  <b-form-group
                    :label="$t('purchases.price') + ' *'"
                    :invalid-feedback="$t('purchases.priceFormValid')"
                    :state="errors.length > 0 ? false : null"
                  >
                    <div class="d-flex align-items-center">
                      <b-form-input
                        id="price"
                        v-model="price"
                        type="number"
                        :min="0"
                        autocomplete="new-password"
                        :state="errors.length > 0 ? false : null"
                      />
                      <div>
                        <strong class="ml-1">
                          {{ currencyType[currency] }}
                        </strong>
                      </div>
                    </div>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="2">
                <b-form-group :label="$t('purchases.stripePriceId')">
                  <b-form-input v-model="stripePriceId" />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group label="">
                  <b-form-radio-group
                    id="btn-radios-1"
                    v-model="elemento"
                    :options="optionsElemento"
                    button-variant="outline-primary"
                    size="lg"
                    name="radios-btn-default"
                    buttons
                  />
                </b-form-group>
              </b-col>
              <b-col
                v-if="elemento"
                md="9"
                class="d-flex align-items-center"
              >
                <b-col md="9">
                  <validation-provider
                    v-slot="{ errors }"
                    name="content"
                    rules="required"
                  >
                    <b-form-group
                      :label="$t('modal.content') + ' *'"
                      :invalid-feedback="$t('purchases.contentFormValid')"
                      :state="errors.length > 0 ? false : null"
                    >
                      <div @click="selectElement(0)">
                        <b-form-input
                          id="content"
                          v-model="contentName"
                          autocomplete="new-password"
                          :state="errors.length > 0 ? false : null"
                          :placeholder="$t('modal.content')"
                          disabled
                        />
                      </div>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col>
                  <b-button
                    variant="info"
                    @click="selectElement(0)"
                  >
                    {{ $t('select') }}
                  </b-button>
                </b-col>
              </b-col>

              <b-col
                v-else
                md="9"
                class="d-flex align-items-center "
              >
                <b-col md="9">
                  <validation-provider
                    v-slot="{ errors }"
                    name="category"
                    rules="required"
                  >
                    <b-form-group
                      :label="$t('modal.category') + ' *'"
                      :invalid-feedback="$t('purchases.categoryFormValid')"
                      :state="errors.length > 0 ? false : null"
                    >
                      <b-col
                        cols="13"
                        @click="selectElement(1)"
                      >
                        <b-form-input
                          id="category"
                          v-model="categoryName"
                          autocomplete="new-password"
                          :state="errors.length > 0 ? false : null"
                          :placeholder="$t('modal.category')"
                          disabled
                        />
                      </b-col>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col>
                  <b-button
                    variant="info"
                    @click="selectElement(1)"
                  >
                    {{ $t('purchases.selectCategory') }}
                  </b-button>
                </b-col>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                v-if="subgroups"
                md="4"
              >
                <b-form-group
                  v-if="hasSubgroups && checkPermissions('users.add_subgroup')"
                  :label="$t('Subgroup')"
                >
                  <v-select
                    id="subgroup"
                    v-model="subgroup"
                    :options="subgroups"
                    label="name"
                    :placeholder="$t('SinGrupoUsuario')"
                    aria-autocomplete="new-password"
                    autocomplete="new-password"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </validation-observer>
        </tab-content>
      </form-wizard>
    </b-overlay>
    <b-modal
      ref="my-modal"
      size="xl"
      hide-footer
      :title="$t('modal.content')"
    >
      <div class="d-block text-center">
        <content-selector
          :is-premium="true"
          @data="SelectContentId"
          @url="SelectContentUrl"
        />
      </div>
      <b-button
        class="mt-3"
        variant="outline-danger"
        block
        @click="hideModal"
      >
        {{ $t("dataGeneric.close") }}
      </b-button>
    </b-modal>
    <b-modal
      ref="cat-modal"
      size="xl"
      hide-footer
      :title="$t('modal.category')"
    >
      <div class="d-block text-center">
        <category-selector
          :columns="categorySelectorTable"
          :is-premium="true"
          @confirm-selection="CategorySelection"
        />
      </div>
      <b-button
        class="mt-3"
        variant="outline-danger"
        block
        @click="hideModal"
      >
        {{ $t("dataGeneric.close") }}
      </b-button>
    </b-modal>
  </div>
</template>
<script>
import {
  BFormGroup,
  BFormInput,
  BButton,
  BRow,
  BCol,
  BFormRadioGroup,
  BOverlay,
} from 'bootstrap-vue'
import { FormWizard, TabContent } from 'vue-form-wizard'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import axios from '@axios'
import {
  messageError, showToast, getSubgroups, checkPermissions,
} from '@/store/functions'

import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import vSelect from 'vue-select'
import { required } from '@validations'
import { getUserData } from '@/auth/utils'
import ContentSelector from '../../common/ContentSelector.vue'
import CategorySelector from '../../common/CategorySelector.vue'

export default {
  components: {
    ContentSelector,
    CategorySelector,
    BFormRadioGroup,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    BOverlay,
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      catTableData: null,
      name: null,
      price: null,
      stripePriceId: null,
      required,
      inapppurchaseContent: null,
      userData: getUserData(),
      contentName: null,
      categoryName: null,
      categorySelectorTable: [
        { key: 'image', label: this.$t('purchases.category') },
        { key: 'name', label: this.$t('dataGeneric.name') },
      ],
      inapppurchaseCategory: null,
      code: null,
      isManual: false,
      options: [
        { text: this.$t('dataGeneric.internal'), value: true },
        { text: this.$t('dataGeneric.external'), value: false },
      ],
      elemento: true,
      optionsElemento: [
        { text: this.$t('purchases.content'), value: true },
        { text: this.$t('purchases.category'), value: false },
      ],

      currencyType: {
        EUR: '€',
        USD: '$',
        GBP: '£',
        ARS: 'Argentine peso',
      },
      show: false,
      currency: null,
      hasSubgroups: false,
      subgroups: null,
      subgroup: null,
      checkPermissions,
    }
  },

  mounted() {
    this.allCategory()
    this.getPayMethod()

    const userData = getUserData()
    this.hasSubgroups = userData.profile.client.hasSubgroups
    if (this.hasSubgroups) {
      getSubgroups().then(result => {
        const { edges } = result.data.data.allSubgroups
        this.subgroups = edges.map(e => e.node)
      }).catch(err => {
        console.log(err)
      })
      this.subgroup = userData.profile.subgroup
    }
  },
  methods: {
    fix(fixDta) {
      const clave = Object.keys(fixDta.failedRules)
      if (clave === 'required') {
        return this.$t('purchases.nameFormValid')
      }
      if (clave === 'min') {
        return this.$t('purchases.nameFormThreeCaract')
      }
      return ''
    },
    formSubmitted() {
      this.show = true

      const clienId = this.userData.profile.client.id

      if (this.isManual) {
        axios
          .post('', {
            query: `
          mutation($stripePriceId: String, $code: String, $subgroup: ID){
               createPurchases(input:{
                    client: "${clienId}"
                    name: "${this.name}",
                    isManual: true,
                    price: 0,
                    stripePriceId: $stripePriceId,
                    code: $code,
                    subgroup: $subgroup,
                }){
                  inAppPurchase{
                    id
                    name
                    price
                    subgroup{
                      id
                      name
                    }
                  }
                }
                }
        `,
            variables: {
              stripePriceId: this.stripePriceId,
              code: '',
              subgroup: this.subgroup?.id,
            },
          })
          .then(res => {
            messageError(res, this)
            const { id } = res.data.data.createPurchases.inAppPurchase
            axios.post('', {
              query: `mutation{
              ${this.inapppurchaseContent ? `updateContents(id:"${this.inapppurchaseContent}",input:{
                inapppurchase:"${id}",
                }){
                  content{
                    id
                  }
                }` : ''}
                ${this.inapppurchaseCategory ? `updateCategory(id:"${this.inapppurchaseCategory}",input:{
                inapppurchase:"${id}",
                }){
                  category{
                    id
                  }
                }` : ''}
            }`,
            }).then(result => {
              messageError(result, this)
              this.show = false

              showToast(this.$t('code.createPurchase'), 1, this)
              this.$router.push('/platform/purchases')
            }).catch(() => {

            })
          })
          .catch(() => {
            this.show = false
            // console.log(res);

            showToast(this.$t('code.errorcreatePurchase'), 2, this)
          })
      } else {
        axios
          .post('', {
            query: `
          mutation($stripePriceId: String, $subgroup: ID){
               createPurchases(input:{
                    client: "${clienId}"
                    name: "${this.name}",        
                    price: ${this.price},
                    code: "${this.code}",
                    stripePriceId: $stripePriceId,                   
                    isManual: false,
                    subgroup: $subgroup,
                  }){
                    inAppPurchase{
                      id
                      name
                      price
                      subgroup{
                        id
                        name
                      }
                    }
                  }
                }`,
            variables: { stripePriceId: this.stripePriceId, subgroup: this.subgroup?.id },
          })
          .then(res => {
            messageError(res, this)
            const { id } = res.data.data.createPurchases.inAppPurchase
            axios.post('', {
              query: `mutation{
              ${this.inapppurchaseContent ? `updateContents(id:"${this.inapppurchaseContent}",input:{
                inapppurchase:"${id}",
                }){
                  content{
                    id
                    inapppurchase{
                      id
                      name
                    }
                  }
                }` : ''}
                ${this.inapppurchaseCategory ? `updateCategory(id:"${this.inapppurchaseCategory}",input:{
                inapppurchase:"${id}",
                }){
                  category{
                    id
                    inapppurchase{
                      id
                      name
                    }
                  }
                }` : ''}
            }`,
            }).then(result => {
              messageError(result, this)
              this.show = false

              showToast(this.$t('code.createPurchase'), 1, this)
              this.$router.push('/platform/purchases')
            }).catch(() => {

            })
          })
          .catch(res => {
            this.show = false
            console.log(res)

            showToast(this.$t('code.errorcreatePurchase'), 2, this)
          })
      }
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.info.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },

    selectElement(elemento) {
      switch (elemento) {
        case 0:
          this.showModal()
          break
        case 1:
          this.$refs['cat-modal'].show()

          break

        default:
          break
      }
    },
    showModal() {
      this.$refs['my-modal'].show()
    },
    hideModal() {
      this.$refs['my-modal'].hide()
      this.$refs['cat-modal'].hide()
    },
    SelectContentId(data) {
      this.inapppurchaseContent = data
      this.hideModal()
    },
    SelectContentUrl(data) {
      this.contentName = data
      this.hideModal()
    },
    CategorySelection(data) {
      this.categoryName = data.node.name
      this.inapppurchaseCategory = data.node.id
      // console.log(data);
      this.hideModal()
    },
    allCategory() {
      axios
        .post('', {
          query: `
            query{  
            allCategories(client:"${this.userData.profile.client.id}"){
              edges{
                node{
                  id
                  name
                  image
                  mediaLocation
                  imageUrl
                }
              }
            }
          }
        `,
        })
        .then(res => {
          messageError(res, this)

          this.catTableData = res.data.data.allCategories.edges
        })
    },
    getPayMethod() {
      const { id } = this.userData.profile.client
      axios
        .post('', {
          query: `
              query{
                client(id:"${id}"){
                  externalPayMethod
                  currency
                }
            }
        `,
        })
        .then(res => {
          messageError(res, this)

          this.currency = res.data.data.client.currency
        })
    },
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>
